.menu {
  list-style: none;
  display: flex;
  justify-content: space-around;
  font-size: larger;
  font-weight: bold;
  color: #f9f9f9;
  padding: 0px;
}

.menu li.selected {
  color: #e2581f;
}

.menu li:hover {
  cursor: pointer;
  text-decoration: underline;
}