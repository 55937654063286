.camerasGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 352px);
  gap: 20px;
  padding: 40px;
  justify-content: center;
}

@media (max-width: 560px) {
  .camerasGrid {
    grid-template-columns: 100%;
    padding: 10px;
  }
}